<template v-slot:content>
  <div>
    <globe-activity-slim-card>
      <template v-slot:header>
        <div class="card-icon">
          <i class="fa fa-globe"></i>
        </div>
        <h4 class="title">Referral Doctors</h4>
      </template>
      <template v-slot:content>
        <div>
          <md-table
            :value="
              list.pages[listInput.page] && list.pages[listInput.page].items
                ? list.pages[listInput.page].items
                : []
            "
            class="
              table-shopping table-striped
              globe-activity
              md-table-global-saless
              PatientRefDoctor
              responsive
            "
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name">{{
                item.firstName + " " + item.lastName
              }}</md-table-cell>
              <md-table-cell md-label="Phone">{{
                item.cellNumber
              }}</md-table-cell>
              <md-table-cell md-label="City">{{
                item.addresses.city
              }}</md-table-cell>
              <md-table-cell md-label="State">{{
                item.addresses.state
              }}</md-table-cell>
              <md-table-cell md-label="Zipcode">{{
                item.addresses.zipCode
              }}</md-table-cell>
              <md-table-cell md-label="Address"
                >{{ item.addresses.address1 }}
                {{ item.addresses.address2 }}</md-table-cell
              >
              <md-table-cell md-label="Specialization">{{
                item.specialization
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </template>
    </globe-activity-slim-card>
  </div>
</template>
<script>
import GlobeActivitySlimCard from "../../components/Globechek/Cards/GlobeActivitySlimCard.vue";
export default {
  name: "ReferralDoctorsScreening",
  components: { GlobeActivitySlimCard },
  inject: ["listInput", "list"],
};
</script>
<style lang="scss" scoped>
.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.md-table-cell {
  // padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
}
.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}

.PatientRefDoctor.responsive td:nth-of-type(1):before {
  content: "Name";
}
.PatientRefDoctor.responsive td:nth-of-type(2):before {
  content: "Phone";
}
.PatientRefDoctor.responsive td:nth-of-type(3):before {
  content: "City";
}
.PatientRefDoctor.responsive td:nth-of-type(4):before {
  content: "State";
}
.PatientRefDoctor.responsive td:nth-of-type(5):before {
  content: "Zipcode";
}
.PatientRefDoctor.responsive td:nth-of-type(6):before {
  content: "Address";
}
.PatientRefDoctor.responsive td:nth-of-type(7):before {
  content: "Specialization";
}
</style>
